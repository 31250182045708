var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.journal,
                      mappingType: _vm.saveType,
                      label: "저장",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveJournal,
                      btnCallback: _vm.saveJournalCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && _vm.isOld,
                        expression: "editable&&isOld",
                      },
                    ],
                    attrs: { label: "삭제", icon: "delete_forever" },
                    on: { btnClicked: _vm.deleteJournal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    label: "일지명",
                    name: "journalName",
                  },
                  model: {
                    value: _vm.journal.journalName,
                    callback: function ($$v) {
                      _vm.$set(_vm.journal, "journalName", $$v)
                    },
                    expression: "journal.journalName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    type: "user",
                    label: "확인자",
                    name: "confirmUserId",
                  },
                  model: {
                    value: _vm.journal.confirmUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.journal, "confirmUserId", $$v)
                    },
                    expression: "journal.confirmUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    default: "today",
                    label: "실시일",
                    name: "actionDate",
                  },
                  model: {
                    value: _vm.journal.actionDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.journal, "actionDate", $$v)
                    },
                    expression: "journal.actionDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.journal.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.journal, "plantCd", $$v)
                    },
                    expression: "journal.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "소방교육 및 훈련일지 정보",
            columns: _vm.grid.columns,
            gridHeight: _vm.gridHeight,
            data: _vm.journal.contents,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            hideBottom: true,
            editable: _vm.editable,
            rowKey: "sopFireFightingJournalContentsId",
            selection: "multiple",
          },
          on: { linkClick: _vm.openContentsPop },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "userCount"
                    ? [
                        props.row[col.name]
                          ? _c(
                              "q-btn",
                              {
                                staticClass: "tableinnerBtn",
                                attrs: {
                                  flat: "",
                                  align: col.align,
                                  color: "blue-6",
                                  label: props.row[col.name],
                                },
                              },
                              [
                                _c(
                                  "q-popup-proxy",
                                  {
                                    ref:
                                      "proxy_" +
                                      props.rowIndex +
                                      "_" +
                                      col.name,
                                  },
                                  [
                                    _c(col.component, {
                                      tag: "component",
                                      attrs: {
                                        props: props,
                                        col: col,
                                        editable: _vm.editable,
                                      },
                                      on: {
                                        callback: (data, color) =>
                                          _vm.callbackProxy(data, props, col),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "교육 추가",
                          icon: "add",
                        },
                        on: {
                          btnClicked: function ($event) {
                            return _vm.addContents("E")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "훈련 추가",
                          icon: "add",
                        },
                        on: {
                          btnClicked: function ($event) {
                            return _vm.addContents("T")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.journal.contents &&
                  _vm.journal.contents.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "제외",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeContents },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }